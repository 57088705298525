import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import DotsAndDashPage from '../../components/Games/Casual/DotsAndDash/DotsAndDash'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import { CASUAL, GAMES, DOTS_DASH } from '../../components/internal-links'
import { DOTSDASH } from '../../components/download-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generatePokerReviewSchemaAndroid,
  generatePokerReviewSchemaIos,
  generateWebsiteSchema,
} from '../../lib/generate-schema'
import { Faq } from '../../components/FAQs/faqs-list-interface'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Games',
    url: GAMES,
  },
  {
    title: 'Casual',
    url: CASUAL,
  },
  {
    title: 'Dots & Dash',
    url: DOTS_DASH,
  },
]

const DotsAndDash: React.FC = () => {
  const [dotsanddashesFaqsSt, setDotsanddashesFaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../../components/FAQs/faqs-list').then(({ dotsanddashesFaqs }) =>
      setDotsanddashesFaqsSt(dotsanddashesFaqs)
    )
  }, [])

  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      desktopBreadcrumbColor="black"
      apkLink={DOTSDASH}
    >
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>
      <SEO
        title="Play Dots & Dash (Dots and Boxes) Online and Win Real Money | Mega"
        description="Play your favourite school time game of dots and boxes online. Download Mega on your Android phone. Play Dots & Dash and win exciting real money rewards."
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        reviewSchemaPokerAndroid={generatePokerReviewSchemaAndroid()}
        reviewSchemaPokerIos={generatePokerReviewSchemaIos()}
        faqSchema={generateFaqSchema(dotsanddashesFaqsSt)}
      />
      <DotsAndDashPage />
    </Layout>
  )
}

export default DotsAndDash
