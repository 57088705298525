import React from 'react'
import './CategoryInfo.scss'
import ReadMoreLessSections from './ReadMoreLessSections'

const DotsAndDashInfo: React.FC = () => {
  const visible = (
    <>
      <h2 className="page-title">Learn more about Dots & Dash</h2>
      <p>
        Remember the pen and paper games on the back of your school notebook?
        Connecting the dots and putting your initials to the boxes? Fighting
        with your schoolmates and challenging them again, till you finally got
        them!
      </p>
      <p>
        Dots and Boxes is the popular game of your childhood - simple on the
        surface but always fun to play. The game is fairly easy to understand
        but demands planning, skill and strategy to master.
      </p>
      <p>
        Ready to bring back your childhood memories and experience your
        favourite pen- paper game, at the comfort of your mobile screens? And
        what if you could play Dots and Boxes online with real players in real
        time, with the added thrill of real money?
      </p>
    </>
  )
  const hidden = (
    <>
      <p>
        Download Mega to play Dots &amp; Dash. Connect the right dots to claim
        more boxes than other players. Showcase your skills in the classic game
        of dots and boxes, and win exciting real money rewards.
      </p>
      <p>
        Dots &amp; Boxes is played on an empty grid of dots. Each player adds a
        horizontal or vertical line between two adjacent dots in their turn. Any
        player who completes the fourth side of a 1x1 box claims the box and
        gets one more turn. When all the dots are connected by the end, the
        player with the most number of boxes to their name wins the game.
      </p>

      <h3>Dots and Dash Rules</h3>
      <p>
        In Dots &amp; Dash, Mega’s multiplayer version of Dots n Boxes, the game
        is played between 2 to 6 players.
      </p>
      <ul>
        <li>
          <p>
            Each player begins with 10 points. To win, the player must ‘race to
            zero’ — reduce points the fastest and get to 0.
          </p>
        </li>
        <li>
          <p>
            The game begins on an empty grid, with each player randomly assigned
            one colour. This will be the colour of the line they draw.
          </p>
        </li>
        <li>
          <p>
            In each turn, a player must draw a dash between any two neighbouring
            dots, within the given time limit. Tap between the chosen dots to
            draw a dash.
          </p>
        </li>
        <li>
          <p>
            Whenever a player closes a box by making the last dash, the score
            reduces by 1 point, and the player gets one more turn.
          </p>
        </li>
        <li>
          <p>
            If a player doesn’t draw a dash within their turn, the game will
            automatically draw a line for them randomly.
          </p>
        </li>
        <li>
          <p>
            The first player to reach zero wins. The total points of the
            remaining players is the score of the winner. This score multiplied
            by the point rate, is the total amount won by the winning player.
          </p>
        </li>
      </ul>

      <h3>Tips</h3>
      <p>
        Most new players try to claim any possible box as quickly as they can.
        But expert players take time to find ways to double cross their
        opponents. A double cross is when you give a short chain of boxes to
        your opponent and then leave them with no choice but to create a long
        chain for you on the next turn. That’s one more skill to master and win
        big!
      </p>

      <h3>Perfectly designed for your phone</h3>
      <p>
        Dots &amp; Boxes is fairly easy to understand and can be enjoyed by
        players of any level. — all you needed was a pen and a paper and some
        friends. Mega brings a fun, exciting version of the game directly to
        your Android phone. Dots and Dash features easy touch controls and
        clean, delightful graphics. Play dots and boxes online — anytime,
        anywhere, with lots of real cash prizes to win.
      </p>

      <h3>Real players, Real fun</h3>
      <p>
        Mega allows only 100% verified profiles to play on the app. Dots &amp;
        Dash is played between 2-6 players in quick short rounds, so that you
        can have a fun casual game of Dots &amp; Boxes without any worries, any
        number of times. Not bots, no tricks, only pure fun.
      </p>
    </>
  )

  return (
    <div className="category-info">
      <ReadMoreLessSections
        visible={visible}
        hidden={hidden}
        gameName="Dots & Dash"
      />
    </div>
  )
}

export default DotsAndDashInfo
